import { AlertBox, AlertBoxDismissButton, Button, Card, FlexBox, Typography } from '@vp/swan'
import { useSwanStyleKeys } from '@vp/ubik-context'
import { Dialog } from './shared/dialog'
import React, { useEffect } from 'react'
import type { FragmentRootOptions } from '@vp/ubik-fragment-types'

export interface Props {
  configurationOverrides?: FragmentRootOptions['configurationOverrides']
}

const hasOverridesText = 'You have configuration overrides in place!'
const noOverridesText = 'No configuration overrides detected.'

export const Fragment = (props: Props) => {
  const { configurationOverrides } = props
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [isAlertCollapsed, setIsAlertCollapsed] = React.useState(false)
  const [isAlertDismissed, setIsAlertDismissed] = React.useState(false)

  useSwanStyleKeys(['core', 'modalDialog', 'alertBox'])

  // Auto-Collapse the alert box after a few seconds
  useEffect(() => {
    setTimeout(() => {
      setIsAlertCollapsed(true)
    }, 5000)
  }, [])

  const alertBoxStyles: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 10000,
    width: isAlertCollapsed ? 'fit-content' : '100%',
    opacity: isAlertCollapsed ? 0.5 : 1,
  }
  const alertCardDisplayState = isAlertCollapsed ? 'none' : 'block'

  const hasOverrides = Object.keys(configurationOverrides || {}).length > 0
  const alertTextBody = hasOverrides ? hasOverridesText : noOverridesText

  return (
    <>
      <AlertBox
        toast
        style={alertBoxStyles}
        dismissed={isAlertDismissed}
        onRequestDismiss={() => {
          if (isAlertCollapsed) {
            setIsAlertDismissed(true)
          } else {
            setIsAlertCollapsed(true)
          }
        }}
      >
        <FlexBox justifyContent='center'>
          {isAlertCollapsed && (<Button skin='primary' compactMode onClick={() => setIsDialogOpen(true)}>View Overrides</Button>)}
          <Card display={alertCardDisplayState}>
            <FlexBox flexDirection='column' justifyContent='center' gap='3'>
              <Typography textAllCaps fontWeight='bold' mb={1} mr={1}>
                {alertTextBody}
              </Typography>
              <Button skin='primary' compactMode onClick={() => setIsDialogOpen(true)}>View Overrides</Button>
            </FlexBox>
          </Card>
        </FlexBox>
        <AlertBoxDismissButton accessibleText='Collapse alert' />
      </AlertBox>

      <Dialog configurationOverrides={configurationOverrides} isOpen={isDialogOpen} onRequestDismiss={() => setIsDialogOpen(false)} />
    </>
  )
}
