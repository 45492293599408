import { Button, ModalDialog, ModalDialogBody, ModalDialogButtons, ModalDialogCloseButton, ModalDialogContent, ModalDialogFooter, ModalDialogHeader, ModalDialogNav, ModalDialogTitle } from '@vp/swan'
import type { FragmentRootOptions } from '@vp/ubik-fragment-types'

const configurationCookieName = 'ubikConfigurationOverrideId'

export type DialogProps = {
  isOpen: boolean
  onRequestDismiss: () => void
  configurationOverrides?: FragmentRootOptions['configurationOverrides']
}

export const getCookieDomain = (hostname: string): string => {
  // get the current domain in the format of '.domain.com', '.domain.co.uk', or 'localhost'
  const domainParts = hostname.split('.')
  const vistaprintDomainIndex = domainParts.findLastIndex((part) => part === 'vistaprint')

  if (vistaprintDomainIndex > -1) {
    return '.' + domainParts.slice(vistaprintDomainIndex).join('.')
  }

  return hostname
}

export const Dialog = (props: DialogProps) => {
  const { isOpen, onRequestDismiss, configurationOverrides = {} } = props

  const handleClearOverrides = () => {
    const domain = getCookieDomain(global.window.location.hostname)

    // Delete overrides cookie
    document.cookie = `${configurationCookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`

    // Reload the page
    global.window.location.reload()
  }

  const configurationValues = JSON.stringify(configurationOverrides, null, 2)
  const hasOverridesDefined = !!configurationOverrides

  return (
    <>
      <ModalDialog
        variant='panel-right'
        bodyWidth='capped'
        isOpen={isOpen}
        onRequestDismiss={onRequestDismiss}
      >
        <ModalDialogContent aria-labelledby='modal dialog menu'>
          <ModalDialogNav>
            <ModalDialogCloseButton accessibleText='Close' />
          </ModalDialogNav>
          <ModalDialogHeader>
            <ModalDialogTitle>Configuration Overrides</ModalDialogTitle>
          </ModalDialogHeader>
          <ModalDialogBody><pre>{configurationValues}</pre></ModalDialogBody>
          <ModalDialogFooter pinned>
            <ModalDialogButtons>
              <Button skin='secondary' onClick={onRequestDismiss}>Cancel</Button>
              <Button skin='primary' disabled={!hasOverridesDefined} onClick={handleClearOverrides}>Clear Overrides</Button>
            </ModalDialogButtons>
          </ModalDialogFooter>
        </ModalDialogContent>
      </ModalDialog>
    </>
  )
}
